 
import api from './apiUsers';
import { global } from './global'

export const data_user = {


    async conectar(data) {
        const { email, password, faceid, appleid } = data

        if (faceid) {
            const response = await api.post('sessions2', { faceid, versao: global.brl.REACT_APP_VERSAO  });

            if (response.data) {
                return response.data
            }
            else { return null }

        }

        if (appleid) {
            const response = await api.post('sessions2', { faceid: appleid, versao: global.brl.REACT_APP_VERSAO  });

            if (response.data) {
                return response.data
            }
            else { return null }

        }

        if (email && password) {
            const response = await api.post('sessions', { email, password, versao: global.brl.REACT_APP_VERSAO  });

            if (response.data) {
                return response.data
            }
            else { return null }

        }
    },

    async me() {

        try {

            const response = await api.get('me');

            if (response.data) { return response.data } else { return null }

        } catch (error) {

            return null
        }


    },

    async novo(data) {

        try {

            const { username, email, password, artist, cpf } = data

            const response = await api.post('users', { username, email, password, artist, cpf, versao: global.brl.REACT_APP_VERSAO  });
            if (response.data) { return response.data } else { return null }



        } catch (error) {
            return null
        }

    },


    async facebook(data) {

        try {

            const { faceid, appleid, faceemail, facename } = data

            const response = await api.post('facebook', { faceid, appleid, faceemail, facename, versao: global.brl.REACT_APP_VERSAO  });


            ///     console.log(response.data, 'api face')

            if (response.data) { return response.data } else { return null }



        } catch (error) {

            ///   console.log(error, 'api face')
            return null
        }

    },


    async tela(id) {

        const response = await api.get(`users/${id}`)
        if (response.data) { return response.data } else { return null }

    },
    async telalink(id) {

        const response = await api.get(`userslink/${id}`)
        if (response.data) { return response.data } else { return null }

    },


    async Listar() {

        const response = await api.get('users/');
        return response.data;


    },


    async atualizar(data) {


     ///   console.log(data)
        try {

            const response = await api.put(`users/${data.id}`, data)
            if (response.data) { return response.data } else { return null }

        } catch (error) {
            return null
        }

    },

    async banir(data) {

        const response = await api.put(`banir/${data.id}`, data)
        if (response.data) { return response.data } else { return null }

    },

    async novasenha(email) {

        const response = await api.put(`recpass`, { email })
        if (response.data) { return response.data } else { return null }

    },

    async atualizarsenha(data) {



        const response = await api.put(`newpass/${data.id}`, data)
        if (response.data) { return response.data } else { return null }



    },

    desconectar() {

        global.token = null

    },
};

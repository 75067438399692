import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";

import { data_fatlives } from '../DataAPI/datafatlives'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MeuContexto } from "../App"


import { validar } from '../DataAPI/apiValidar'

import CompActive from '../Componentes/CompActive'

import './PgCard.css';

import iugulogo from '../img/pagamentos-por-iugu.png'




function PgCard({ artista, publico, close }) {

    let history = useHistory();
    const { logado, setlogado } = useContext(MeuContexto)

    const [isloading, setisloading] = useState(false)

    const [card_number, setcard_number] = useState('')
    const [card_holder_name, setcard_holder_name] = useState('')
    const [card_expiration_date, setcard_expiration_date] = useState('')
    const [card_cvv, setcard_cvv] = useState('')

    // const [card_hash, setcard_hash] = useState('')

    const [price_cents, setprice_cents] = useState(10)
    const [email, setemail] = useState('')
    const [cpf_cnpj, setcpf_cnpj] = useState('')


    const [card_hash, setcard_hash] = useState('')



    useEffect(
        () => {

            if (artista.paga) {

                setprice_cents(artista.preco)
            }

            setemail(publico.email)



        },
        []
    )

    useEffect(
        () => {

            if (!logado) { history.push("/SignIn") }
        }, [logado])

    function _validar() {
       /// console.log(card_holder_name,card_number,card_expiration_date,card_cvv,cpf_cnpj,'-------valido')
 


        if (!validar._requerido(card_holder_name).valido) return false
        if (!validar._requerido(card_number).valido) return false
        if (!validar._requerido(card_expiration_date).valido) return false
        if (!validar._requerido(card_cvv).valido) return false
        if (!validar._requerido(cpf_cnpj).valido) return false

        if (!validar._cpf(cpf_cnpj).valido) return false
        if (!validar._cartao(card_number).valido) return false
        if (!validar._cartaodata(card_expiration_date).valido) return false
        if (!validar._cartaocvv(card_cvv).valido) return false

       return true
    }

    function _dtcartao() {

        var ndata = card_expiration_date

        var ndataaux = ''
        if (ndata.length === 4) {

            ndataaux = `${ndata.substring(0, 2)}/20${ndata.substring(2, 4)}`

        } else if (ndata.length === 5) {

            ndataaux = `${ndata.substring(0, 2)}/20${ndata.substring(3, 5)}`

        } else if (ndata.length === 6) {

            ndataaux = `${ndata.substring(0, 2)}/${ndata.substring(2, 6)}`

        } else {

            ndataaux = ndata
        }

        setcard_expiration_date(ndataaux)


    }



    async function _salvar() {


        if (await _validar()) {
         // console.log('-------valido')
            await pagar()

        }
        else {
            toast.warn(

                'Favor verificar informações do cartão de crédito!'
            );
            setisloading(false)

        }



    }

    async function pagar() {




        setisloading(true)


        var vrfirstName = card_holder_name.split(' ')[0].toUpperCase();
        var vrlastName = card_holder_name.split(' ').slice(1).join(' ').toUpperCase();

        var vrmes = card_expiration_date.split('/')[0];
        var vrano = card_expiration_date.split('/').slice(1).join('/');

        const datapagamento = {
            card_number,
            card_cvv,
            vrfirstName,
            vrlastName,
            vrmes,
            vrano,
            price_cents,
            cpf_cnpj,
            card_holder_name,
            email: publico.email,
            liveemail: artista.email,
            username: publico.username,
            livename: artista.username,
            livepaga: artista.paga,
            userid: publico._id,
            liveid: artista._id,
        }



       // console.log(datapagamento, '------ datapagamento resposta')

        const resposta = await data_fatlives.pagariugu(datapagamento)

     // console.log(resposta, '------datapagamento resposta')
        if (resposta) {


            const { rid, rmsg } = resposta
            if (rid !== 0) {


                toast.success(

                    rmsg
                );

                close()

            } else {
                toast.error(

                    rmsg
                );
            }




        } else {
            toast.error(

                "ERRO NO CARTÃO DE CRÉDITO!"
            );
        }


        setisloading(false)


    }

    return (


        <>  <ToastContainer position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false} />

            <div className="tlinha111" >

                {(isloading) ? (<><CompActive /></>) : (<>



                    <div>
                        {(artista.paga) ? (<>
                            <div className="tlinha1" >
                                <h3>Valor: <span className="tlbamarelo">{price_cents} reais</span></h3>
                                <br></br>
                                <h5>O valor do ingresso acima será direcionado ao artista   <span className="tlbbranco">{artista.username}</span>  e terá a duração de 5 dias corridos.</h5>
                            </div>
                        </>) : (<>
                            <div className="tlinha1" >
                                <div>

                                    <h3>Valor: <span className="tlbamarelo">{price_cents} reais</span></h3>


                                </div>
                                <div>
                                    <input className={"tubtinput tBtInativo"} type="button" onClick={() => { setprice_cents(price_cents + 10) }} value="+10 reais" />

                                    <input className={"tubtinput tBtInativo"} type="button" onClick={() => { (price_cents < 20) ? setprice_cents(10) : setprice_cents(price_cents - 10) }} value="-10 reais" />
                                </div>
                                <h5>O valor da contribuição voluntária acima será direcionado ao artista <span className="tlbbranco">{artista.username}</span></h5>

                            </div>
                        </>)}

                    </div>
                    <form className={"xform"} autoComplete="off">


                    <div className={"separadiv"} >
            


                        <label className={"xlabel"} for="card_number">Número do cartão:</label>
                        <input
                            className={" xinput"}
                            autoComplete="off"
                            placeholder="0000 0000 0000 0000"
                            name="card_number"
                            type="text"
                            value={card_number}
                            onChange={e => setcard_number(e.target.value)} />
 
                        <label className={"xlabel"} for="card_expiration_date">Data de expiração:</label>
                        <input
                            className={"xinput"}
                            autoComplete="off"
                            placeholder="mm/aaaa"
                            name="card_expiration_date"
                            type="text"
                            onBlur={() => { _dtcartao() }}
                            value={card_expiration_date}
                            onChange={e => setcard_expiration_date(e.target.value)} />

                        <label className={"xlabel"} for="card_cvv">Código de segurança:</label>
                        <input
                            className={"xinput"}
                            autoComplete="off"
                            placeholder="******"
                            name="card_cvv"
                            type="password"
                            value={card_cvv}
                            onChange={e => setcard_cvv(e.target.value)} />
 
    
                        <label className={"xlabel"} for="card_holder_name">Nome do Titular:</label>
                        <input
                            className={"xinput"}
                            autoComplete="off"
                            placeholder="Nome igual no cartão"
                            name="card_holder_name"
                            type="text"
                            value={card_holder_name}
                            onChange={e => setcard_holder_name(e.target.value.toUpperCase())} />


                        <label className={"xlabel"} for="cpf_cnpj ">CPF do Titular:</label>
                        <input
                            className={"xinput"}
                            autoComplete="off"
                            placeholder="111.111.111/11"
                            name="cpf_cnpj"
                            type="text"
                            value={cpf_cnpj}
                            onChange={e => setcpf_cnpj(e.target.value)} />


</div>


                        <div className="xbutton tudiv2">
                            <button className="tbtcancelar" type="button" onClick={close} >Cancelar</button>
                            <button className="tbtsalvar" type="button" onClick={(e) => { _salvar(e) }} >Pagar</button>



                        </div>





                    </form>

                    <div className="cardiugulogo">


                        <a href="https://iugu.com/" target="_blank">
                            <img className={"iugulogo"} src={iugulogo} alt="iugulogo"></img>
                        </a>

                    </div>






                </>)
                }
            </div >
        </>);
}

export default PgCard;
import api from './apiemail';

export const data_email = {




    rsenha(email, senha) {

        api.post(`senha`, { email, senha })


    },
}
import React, { useEffect, useContext } from 'react';

import { data_live } from '../DataAPI/datalive'


import { MeuContexto } from "../App"

//import QRCode from "react-qr-code";

import Hls from "hls.js";
import './CompVideo.css'
let vrplayer = null
let hls = null
function Video({ lurl, lid, contar }) {


  //const { perfil } = useContext(MeuContexto)


  useEffect(() => {
    _CarregarTela();

    try {
      if (contar) data_live.liveentrar(lid);
    } catch (error) {



    }
 



    return () => {
      try {
        if (contar) data_live.livesair(lid);
      } catch (error) {

      }

      try {
        hls.destroy();
      } catch (error) {

      }

    }
  }, [])



  function _CarregarTela() {
    if (hls) {
      hls.destroy()

    }
    var video = vrplayer

    var url = lurl
    /// console.log(lurl)
    var videoSrc = url  ///url/`${url}?sv=2019-12-12&ss=b&srt=sco&sp=rl&se=2022-11-20T05:21:45Z&st=2020-11-19T21:21:45Z&spr=https&sig=vxpoAvnryz9eguUurvrF%2F5IWP88lUnAl3f1AtwoMFkE%3D`   ///'https://judahtv.blob.core.windows.net/blob/5f85ced340ddd651ba8fa7d9/index.m3u8'
    if (url.length > 0) {
      if (url.substring(url.length - 5, url.length) === '.m3u8') {


        ////   console.log('teste')

        if (Hls.isSupported()) {

          var config = {

            autoStartLoad: true,
            startPosition: -1,
            enableWorker: true,

            liveBackBufferLength: 4000,
            liveSyncDurationCount: 10,
            //  xhrSetup: function (xhr, url) {
            //     xhr.withCredentials = true; // do send cookie
            //    xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With");
            //    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            //   xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
            //  }

          };
          hls = new Hls(config);

          // console.log(url, config)

          hls.attachMedia(video);
          hls.on(Hls.Events.MEDIA_ATTACHED, function () {
            //   console.log("video and hls.js are now bound together !");
            hls.loadSource(videoSrc);
            hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
              //   console.log("manifest loaded, found " + data.levels.length + " quality level");

              video.play();

 
            });

          });

        }


        else if (video.canPlayType('application/vnd.apple.mpegurl')) {

          video.src = videoSrc;

          video.addEventListener('loadedmetadata', function () {
            video.play();
 
          });
        }
      } else {


        ////  console.log('teste2')
        video.src = videoSrc;
        video.addEventListener('loadedmetadata', function () {
          video.play();
 
        });


      }
    }
  }
  return (<div>
    <video
      controls controlsList="nodownload"
      className="lvideoplay"
      ref={player => (vrplayer = player)}
      autoPlay={true} loop 
    />

    {/*  <div style={{ bottom: 60, left: 60, padding: 10, backgroundColor: 'white', position: 'absolute' }}>
      <p>PIX</p>
      <QRCode value={perfil.pixchave} level="L" size="120" />
    </div>
*/}
  </div>);
}

export default Video;

import React from 'react';

import './CompActive.css';

function CompActive() {
    return (
        <div class="loader">Loading...</div>
        );
}

export default CompActive;
import api from './apiLives';

import { global } from '../DataAPI/global'

export const data_live = {

    async tela(id) {

        const response = await api.get(`users/${id}`)
        if (response.data) { return response.data } else { return null }

    },

    async Listar(oid, page, filter) {
        try {
            if (filter != null) {
                const response = await api.get(`liveslist2ap/${oid}/${page}/${filter}`);
                if (response.data) { return response.data } else { return null }
            } else {
                const response = await api.get(`liveslist2ap/${oid}/${page}`);
                if (response.data) { return response.data } else { return null }
            }
        } catch (error) {
            return null
        }
    },

    async Listar1(oid, page, filter) {
        try {
            if (filter != null) {
                const response = await api.get(`liveslist2bp/${oid}/${page}/${filter}`);
                if (response.data) { return response.data } else { return null }
            } else {
                const response = await api.get(`liveslist2bp/${oid}/${page}`);
                if (response.data) { return response.data } else { return null }
            }
        } catch (error) {
            return null
        }
    },

    async Listar2(oid, page, filter) {
        try {
            if (filter != null) {
                const response = await api.get(`liveslist2cp/${oid}/${page}/${filter}`);
                if (response.data) { return response.data } else { return null }
            } else {
                const response = await api.get(`liveslist2cp/${oid}/${page}`);
                if (response.data) { return response.data } else { return null }
            }
        } catch (error) {
            return null
        }
    },

    async liveentrar(id) {

        var data = { versao: global.brl.REACT_APP_VERSAO }

        const response = await api.put(`liveentrar/${id}`, data)
        if (response.data) { return response.data } else { return null }




    },

    async livesair(id) {

        var data = { versao: global.brl.REACT_APP_VERSAO }

        const response = await api.put(`livesair/${id}`, data)
        if (response.data) { return response.data } else { return null }

    },





};

import React from 'react';

import './PgaSobre.css';

function PgaSobre() {
    return (
        <div className="sobre">
             
            <h1  >Sobre</h1>
            <br></br> 

            <h3>MEUPASTOR TV</h3>
            <br></br>
            <p>Transmitimos os melhores canais evangélicos para sua comodidade e segurança</p>
            <br></br>
            <p>Para duvidas, suporte ou criar um canal ligue</p>
            <p>Ivan Adelino (11) 99981-3774</p>
            <p>ivan@bascode.com</p>
             <br></br> 
      
            <p>Tenho muita PAZ</p>
            <br></br>
            <br></br>
            
        </div>

    );
}

export default PgaSobre;